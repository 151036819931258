<template>
  <base-layout>
    <router-view />
  </base-layout>
</template>

<script>

import BaseLayout from '@/layouts/BaseLayout.vue'

export default {
  components: { BaseLayout }
}
</script>
